<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col cols="12" sm="6" md="8">
        <div>
          <h1>EDIT BID PACK</h1>
        </div>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EnterBid',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <customer-info />

    <v-row>
      <v-col cols="12" md="6">
        <v-data-table
          :headers="order_headers"
          :items="order_items"
          class="elevation-1 cursor-pointer"
        >
          <template v-slot:item.removeIcon="{ item }">
            <v-icon class="mr-5" color="error" v-model="item.removeIcon"
              >mdi-close-circle-outline</v-icon
            >
            <v-icon v-model="item.removeIcon">mdi-arrow-right</v-icon>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" md="6">
        <v-data-table
          :headers="move_headers"
          :items="move_items"
          class="elevation-1 cursor-pointer"
        >
          <template v-slot:item.moveIcon="{ item }">
            <v-icon class="mr-5" v-model="item.moveIcon">mdi-arrow-left</v-icon>
            <v-icon color="cyan" v-model="item.moveIcon"
              >mdi-plus-circle-outline</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
export default {
  name: "AddPDF.vue",
  components: { CustomerInfo },
  data() {
    return {
      evaluation_id: this.$route.params.id,
      order_headers: [
        { text: "Order", value: "order" },
        { text: "Media Item", value: "mediaItem" },
        { text: "Remove Icon", value: "removeIcon" },
      ],
      move_headers: [
        { text: "Move Icon", value: "moveIcon" },
        { text: "Other Media", value: "otherMedia" },
        { text: "In Bid", value: "inBid" },
      ],
      order_items: [
        {
          order: "100",
          mediaItem: "Covor",
          removeIcon: "",
        },
        {
          order: "100",
          mediaItem: "Billy - help",
          removeIcon: "",
        },
        {
          order: "100",
          mediaItem: "Letter - Curt",
          removeIcon: "",
        },
        {
          order: "100",
          mediaItem: "Repair Plan Detail",
          removeIcon: "",
        },
        {
          order: "100",
          mediaItem: "Photos",
          removeIcon: "",
        },
        {
          order: "100",
          mediaItem: "Drawing",
          removeIcon: "",
        },
      ],
      move_items: [
        {
          moveIcon: "",
          otherMedia: "Pier Sheet",
          inBid: "Yes",
        },
        {
          moveIcon: "",
          otherMedia: "Pier Sheet",
          inBid: "Yes",
        },
        {
          moveIcon: "",
          otherMedia: "Pier Sheet",
          inBid: "Yes",
        },
        {
          moveIcon: "",
          otherMedia: "Pier Sheet",
          inBid: "Yes",
        },
        {
          moveIcon: "",
          otherMedia: "Pier Sheet",
          inBid: "Yes",
        },
        {
          moveIcon: "",
          otherMedia: "Pier Sheet",
          inBid: "Yes",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
