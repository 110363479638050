var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"margin-bottom":"100px"}},[_c('v-row',{staticClass:"sc-title mt-7",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('div',[_c('h1',[_vm._v("EDIT BID PACK")])])]),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"3"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
              name: 'EnterBid',
              params: { id: _vm.$route.params.id },
            })}}},[_vm._v("Back ")])],1)])],1),_c('customer-info'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-data-table',{staticClass:"elevation-1 cursor-pointer",attrs:{"headers":_vm.order_headers,"items":_vm.order_items},scopedSlots:_vm._u([{key:"item.removeIcon",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5",attrs:{"color":"error"},model:{value:(item.removeIcon),callback:function ($$v) {_vm.$set(item, "removeIcon", $$v)},expression:"item.removeIcon"}},[_vm._v("mdi-close-circle-outline")]),_c('v-icon',{model:{value:(item.removeIcon),callback:function ($$v) {_vm.$set(item, "removeIcon", $$v)},expression:"item.removeIcon"}},[_vm._v("mdi-arrow-right")])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-data-table',{staticClass:"elevation-1 cursor-pointer",attrs:{"headers":_vm.move_headers,"items":_vm.move_items},scopedSlots:_vm._u([{key:"item.moveIcon",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5",model:{value:(item.moveIcon),callback:function ($$v) {_vm.$set(item, "moveIcon", $$v)},expression:"item.moveIcon"}},[_vm._v("mdi-arrow-left")]),_c('v-icon',{attrs:{"color":"cyan"},model:{value:(item.moveIcon),callback:function ($$v) {_vm.$set(item, "moveIcon", $$v)},expression:"item.moveIcon"}},[_vm._v("mdi-plus-circle-outline")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }